import React, { Component, PureComponent, useContext } from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom'
import Catering from './Catering'
import About from './About'
import Contact from './Contact'
import QRMenu from './QRMenu'
import Kitchen from './Kitchen'
import Bakery from './Bakery'
import Merch from './Merch'
import Form from './Form'
import './index.scss'
import './altStyleA.scss'
import './ThemeToggle/theme_night.scss'
import './wine.scss'
import ThemeContext from '../../Context/ThemeContext'

import urls from './urls'
import AutoMenu from '../../react/AutoMenu'
import AutoMenuCustom from './AutoMenu'



import {sections} from './sections'
import ThemeToggle from './ThemeToggle'
import CartBar from './CartBar'
export {CartBar as StickyTopBar}

const wingFlavors = ["Chicken Parmesan", "Hot Pastrami", "Philly Steak", "Chicken Pesto", "Meatball", "BBQ Chicken", "Cold Cuts",]

const urlMappings = urls.filter(({content, redirect}) => {
  return !!content || !!redirect
}).map(({origin, content, redirect}) => {
  const pageObj = { path: origin, unlisted: true }
  if (content) {
    pageObj.component = content
  }
  if (redirect) {
    console.log(redirect)
    pageObj.component = () => (<Redirect to={redirect}></Redirect>)
  }
  return pageObj
})


export const customOrderAppPaths = []

export const pages = [
  {path: "/", label: "Home" },
  {path: "/table/:tableNumber", hidden: true, component: Table },
  {path: "/order", label: "Order" },
  {path: "/menu", label: "Menu", component: Menu },
  // {path: "/merch", label: "Merch", component: Merch },
  {path: "/house", hidden: true, notExact: true, component: QRMenu },
  // {path: "/house/:categoryId", hidden: true, component: QRMenu },
  {path: "/all", hidden: true, component: AllItems},
  {path: "/all/:id", label: "Menu", component: AllItems, hidden: true },
  // {path: "/catering", label: "Catering", component: Catering },
  // {path: "/about/the-cafe", label: "About", component: About },
  // {path: "/find-us", label: "Find Us", component: Contact },
  // {path: "/catering", label: Caterings", component: Catering },
].concat(urlMappings)

export class SiteNav extends PureComponent {
  render() {
    return (null)
    return (
    <nav aria-label="secondary" className="pages-nav">
      <ul>
        {pages.map(({path, label, external, hidden, unlisted}) => {
          if (hidden || unlisted) { return null}
          return (
          <li>
            {external ?
              <a className="page-link" href={path}>{label}</a> :
              <NavLink className="page-link" exact to={path}>{label}</NavLink>}
          </li>
        )})}
      </ul>
      {/* <ThemeToggle></ThemeToggle> */}
    </nav>
  )}
}

export default function AllItems ({match}) {
  const id = match.params.id
  return(
    <div className="landing-page-wrapper">
      <div className="">
        <div
            className="page-header">
          <div 
          className="text">
              <h1 style={{
            textAlign: 'center',
            fontWeigth: 800,
            fontSize: "1.5em"
          }}>All Menu Items</h1>
      
      
          </div>
      
          </div>
      {/*
          <p
          style={{
            textAlign: 'center',
            fontWeight: 700
          }}
          ><a href="/public/mikaza/mikaza-v2.pdf">PDF Menu</a></p>         */}
        {id ?
        <AutoMenuCustom
        categorysToShow={[id]}
        disableOrder={true}
      ></AutoMenuCustom>
        :
        <AutoMenuCustom
          disableOrder={true}
        ></AutoMenuCustom>
      }
      
      
      </div>
    </div>
  )
}

const quickLinks = [
  { to: '/order', label: 'order online'},
  { to: '/bakery', label: 'bakery'},
  { to: '/breakfast-lunch', label: 'marketplace'},
  { to: '/catering', label: 'catering'},
]

const foodImages = [
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-1.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-2.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-3.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-4.jpg"},
  { url: "https://afag.imgix.net/romancing-the-bean-cafe/food-5.jpg"}
]



export function Header () {
    const now = new Date()
    const day = now.getDay() // Sunday - Saturday : 0 - 6
    const hours = now.getHours() //0 to 23
    const isMorning = (hours < 16)

    const {themeName} = useContext(ThemeContext)
    const heroUrl = (themeName === 'dark') ?
      "https://afag.imgix.net/romancing-the-bean-cafe/espress-dark.jpg?w=500"
      :
     "https://afag.imgix.net/romancing-the-bean-cafe/espresso-light.jpg?w=500"
    return (
      <div className="landing-page-wrapper">

        <div className="home-page-blocks">

        <div className="logo-block">
            <img src="https://afag.imgix.net/mexcocina-cafe-authentic-mexican-food-and-cafe/mexcocina-cafe-logo.png?w=1000&auto=format" alt="Mexcocina Cafe" />
          </div>
          

          {/* <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/hero.jpg?w=2000&h=1000&fit=crop&auto=format" alt="pizza" />
          </div> */}

          <div className="text-paragraph">
            <p>
            MexCocina Café is a reflection of where it all began: Santa Rosalía, a humble town, which is located in the municipality of Ayutla in the Mexican State of Jalisco. Our food and hospitality reflect the comfort and authenticity of our humble town. Our mission is to provide great tasting authentic Mexican and American food as well as exceptional service!
            </p>

          </div>

          {/* <div className="text-block">
          <p>We Speak 3 Languages:</p>
        <p>English: OK, 普通话: OK, 한국어: OK</p>
          </div> */}

          <div className="text-block">
            <div className="online-order-card">
              <h2>Order Online</h2>
              <div className="location-buttons">
              {/* <p><Link to={{pathname: "/menu", state: {startAtTop: true}}}   className="menu-button">View Menu</Link></p> */}
                <Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">Pick Up</Link>
                <Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">Delivery</Link>
                {/* <Link to="#" className="order-button soon">Delivery</Link> */}
              </div>
            </div>
          
          </div>


          <div className="text-block">
              <div className="address">
              {/* <h2>ADDRESS</h2> */}
              <p><a href="https://www.google.com/maps/place/MexCocina+Cafe/@34.177719,-118.340648,15z/data=!4m2!3m1!1s0x0:0xc7f52cf61c77b8a?sa=X&ved=1t:2428&ictx=111">
              2714 W. Burbank Blvd.
                </a></p>
                
              <p>Burbank, CA 91505</p>
              {/* <h2>PHONE</h2> */}
              <p><a href="tel:+18188450561">(818) 845-0561</a></p>
              {/* <h2>HOURS</h2> */}
              <p>Mon-Sat</p>
              <p>8am - 8pm</p>
              <p>Sunday</p>
              <p>8am - 3pm</p>
              {/* <p>*delivery ends at 9:15pm</p> */}
            </div>
          </div>


          {/* <div className="text-paragraph">
            <p>
            我们专注于提供可定制的火锅和麻辣香锅选择，邀请您根据自己的口味偏好来定制您的盛宴。尽情享受我们的街头风格烤串和经典中式菜肴，完成一次完整的美食之旅。加入我们，开启一次大胆而激动人心的用餐冒险，每一口都是火辣的庆祝。
            </p>

          </div> */}

          {/* <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/sub.jpg?w=1500&auto=format" alt="wisubsngs" />
          </div> */}

          {/* <div className="text-block flavors">
            <h2>Subs</h2>
            
            {wingFlavors.map((flavor) => {
              return(
                <span className="flavor">{flavor}</span>
              )
            })}
            
          </div> */}
{/* 
          <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/pizza.jpg?w=1500&auto=format" alt="wisubsngs" />
          </div> */}

          {/* <div className="text-block flavors">
            <h2>Pizzas</h2>
            
            {wingFlavors.map((flavor) => {
              return(
                <span className="flavor">{flavor}</span>
              )
            })}
            
          </div> */}

          {/* <div className="image-block">
            <img src="https://images.unsplash.com/photo-1532634922-8fe0b757fb13?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2344&h=1000&q=80" alt="wings" />
          </div> */}
           {/* <div className="image-block">
            <img src="https://afag.imgix.net/tang-huo-kung-fu-spicy-hot-pot/bg.webp?w=2400&auto=format" alt="pizza" />
          </div> */}

          {/* <div className="text-block catering">
            <h2>CATERING</h2>
            <p>Make your next event a hit with our top-notch catering service. Offering a mouth-watering selection of pizzas, subs, and wings, we've got all the flavors to keep your guests talking. Convenient, delicious, and always a crowd-pleaser—let us handle the food so you can focus on the fun!</p>
          </div>

          <div className="image-block">
            <img src="https://afag.imgix.net/the-pizzeria-factory/tshirt-1.png?w=1200&auto=format" alt="tshirt" />
          </div>


          <div className="text-block catering">
            <h2>MERCH</h2>
            <p>Heat up your style with our soon-to-launch T-shirt merch! Show off your love for pizza with our vibrant, sauce-inspired designs. They're more than just a shirt—they're a badge of honor for true heat enthusiasts. Keep an eye out, because it's about to get even hotter in here!</p>
          </div> */}

          
          <Form></Form>
          
{/* 
          <div className="text-block ordering">
            <div className="text">
              <p><Link to={{pathname: "/menu", state: {startAtTop: true}}}   className="menu-button">View Menu →</Link></p>
              <p><Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">Order Pickup / Delivery →</Link></p>
            </div>
          </div> */}

        </div>
       
        {/* <Form></Form> */}

       



       







      </div>
    );

}


function Menu () {
  return (
    <div className="ordering-page">
        
        <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
  )
}
function Table ({match}) {
  const tableNumber = match.params.tableNumber
  return (
    <div className="table-page">
        <div className="table-controls">
          <div>

          Table Number:
          </div>
          <div className='number'>

          {tableNumber}
          </div>


          <div className="">
          <Link to={{pathname: "/menu", state: {startAtTop: true}}} className="order-button">MENU</Link>
          <Link to={{pathname: "/order", state: {startAtTop: true}}} className="order-button">ORDER</Link>
          <Link to={{pathname: "", state: {startAtTop: true}}} className="order-button">Call Staff</Link>
          </div>
        </div>
        {/* <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu> */}
      </div>
  )
}

export class OrderingHeader extends PureComponent {
  render() {
    return (
      <div className="ordering-page">
        {/* <div className="ordering-header">
        
        
        </div> */}
        <AutoMenu altStyleA subMenuFilter={subMenuFilter}></AutoMenu>
      </div>
    );
  }
}

export function subMenuFilter (subMenu) {
  const tag = subMenu.tag
  if (!tag) {return true}

  const now = new Date()
  const day = now.getDay() // Sunday - Saturday : 0 - 6
  const hours = now.getHours() //0 to 23
  const minutes = now.getMinutes() // 0 to 59
  const isWeekday = [1,2,3,4].indexOf(day) !== -1
  const isLunch = (
    (hours >= 11) && (hours < 22)
  )
  const isBreakfast = (
    (hours < 15)
  )

  const isPromo =     tag.indexOf('promo') !== -1 
  const isBreakfastMenu =     tag.indexOf('breakfast') !== -1 
  const isLunchMenu =     tag.indexOf('lunch') !== -1 
  const isSouthOfBorderMenu =     tag.indexOf('south-of') !== -1 
  const isDinnerMenu =     tag.indexOf('dinner') !== -1 
  const isQRCode =     tag.indexOf('qr-only') !== -1 
  
  if (isQRCode) {
    return false
  }

  if (isBreakfastMenu) {
    console.log('breakfast', hours)
    if ((hours < 16) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // no monday
      return true
    } else if ((hours >= 21) && ([0,1,2,3,4,5,6].indexOf(day) !== -1)) {
      // breakfasat preorder
      return true
    } else {
      return false
    }
  }
  if (isLunchMenu) {
    if ((hours>= 11) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isSouthOfBorderMenu) {
    if ([1,2,3,4,5].indexOf(day) !== -1) {
      
      if ((hours>= 10) && (hours < 21)) {
        return true
      } else {
        return false
      }
    } else {
      if ((hours>= 16) && (hours < 21)) {
        return true
      } else {
        return false
      }
    }
  }
  if (isDinnerMenu) {
    if ((hours >= 16) && (hours < 21)) {
      return true
    } else {
      return false
    }
  }
  if (isPromo) {return false}

  return true
}
