
import * as Components from "./Mexcocina"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "mexcocina"
}

